<template>
  <div>
    <ca-header
      heading="Fahrzeug zum FB hinzufügen"
      :loading="loading"
    >
      <template #heading>
        <h3 class="mb-0">
          Benutzer - <span v-if="user && user._id">{{ user.firstname }} {{ user.lastname }}</span>
        </h3>
      </template>
      <template #alert>
        <transition name="slide-fade">
          <ca-alert
            v-if="!!userSaveErrorMessage"
            variant="danger"
            :message="userSaveErrorMessage"
            @close="userSaveErrorMessage = null"
          />
        </transition>
        <transition name="slide-fade">
          <ca-alert
            v-if="!!error"
            variant="danger"
            :message="error"
            @close="error = null"
          />
        </transition>
        <transition name="slide-fade">
          <ca-alert
            v-if="!!userSaveSuccessMessage"
            variant="success"
            :message="userSaveSuccessMessage"
            @close="userSaveSuccessMessage = null"
          />
        </transition>
        <transition name="slide-fade">
          <ca-alert
            v-if="showFormInvalidHint"
            variant="info"
            message="Es sind noch nicht alle erforderlichen Felder ausgefüllt"
            @close="showFormInvalidHint = null"
          />
        </transition>
      </template>
      <template #buttonSpace>
        <button
          v-if="$can('editAllUsers')"
          :disabled="userSavePending"
          class="btn btn-success text-white"
          @click="save()"
        >
          Speichern
        </button>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <div
        class="btn-group mb-3"
      >
        <button
          type="button"
          class="btn"
          :class="{ 'btn-primary' : (tab === 0), 'btn-secondary' : (tab !== 0) }"
          @click="tab = 0"
        >
          Partnerdaten
        </button>
        <!-- <button
          type="button"
          class="btn"
          :class="{ 'btn-primary' : (tab === 20), 'btn-secondary' : (tab !== 20) }"
          @click="tab = 20"
        >
          Zahlungsdaten
        </button> -->
        <!-- <button
          type="button"
          class="btn"
          :class="{ 'btn-primary' : (tab === 4), 'btn-secondary' : (tab !== 4) }"
          @click="tab = 4"
        >
          Sicherheit
        </button> -->
        <button
          type="button"
          class="btn"
          :class="{ 'btn-primary' : (tab === 6), 'btn-secondary' : (tab !== 6) }"
          :disabled="!$can('editAllUsersRoles')"
          @click="tab = 6"
        >
          Admin
        </button>
      </div>
      <div class="row">
        <div class="col-12 col-xl-8 col-xxl-6">
          <PartnerdatenForm
            v-if="tab==0 && !loading"
            :user="user"
          />
          <!-- <SicherheitForm
            v-if="tab==4 && !loading"
            :user="user"
          /> -->
          <AdminForm
            v-if="tab==6 && !loading"
            :user="user"
          />
          <UserEmailLogs
            v-if="tab==6 && !loading && $can('seeEmailLogs')"
            :user="user"
            class="mb-3"
          />
          <PaymentForm
            v-if="tab==20 && !loading"
            :user="user"
          />
        </div>
        <div class="col-12 col-xl-4">
          <RelatedOffers
            v-if="orders"
            :orders="orders"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import RelatedOffers from '@/components/RelatedOffers.vue'
import formatDate from '../../filters/formatDate.js'
import iban from 'iban'
import PartnerdatenForm from '@/components/Forms/Trader/PartnerdatenForm.vue'
import AdminForm from '@/components/Forms/Trader/AdminForm.vue'
import UserEmailLogs from './UserEmailLogs.vue'
import PaymentForm from '@/components/Forms/Trader/PaymentForm.vue'

export default {
  name: 'UserEdit',
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    CaHeader,
    RelatedOffers,
    PartnerdatenForm,
    AdminForm,
    UserEmailLogs,
    PaymentForm
  },
  filters: {
    formatDate
  },
  data () {
    return {
      loading: false,
      user: null,
      bankDetailsList: [],
      error: null,
      userSavePending: false,
      userSaveSuccessMessage: null,
      userSaveErrorMessage: null,
      showFormInvalidHint: false,
      orders: null,
      tab: 0
    }
  },
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.fetchData()
      }
    }
  },
  methods: {
    async save () {
      try {
        const valid = await this.$validator.validateAll()
        if (this.userSavePending) {
          return
        }
        if (!valid) {
          this.showFormInvalidHint = true
          return
        }
        this.userSaveErrorMessage = null
        this.showFormInvalidHint = false
        this.userSavePending = true
        this.user.bankId = []
        await this.saveBankDetails()
        await this.user.save()
        this.userSavePending = false
        this.userSaveSuccessMessage = 'Speichern erfolgreich'
      } catch (error) {
        this.userSaveErrorMessage = error.message
        this.userSavePending = false
      }
    },
    async saveBankDetails () {
      for (const bank of this.bankDetailsList) {
        if (iban.isValid(bank.IBAN) || !bank.IBAN) {
          const savedBank = await bank.save()
          this.user.bankId.push(savedBank._id)
        } else {
          throw new Error(`${bank.VWZ}: Bitte IBAN überprüfen`)
        }
      }
    },
    async fetchData () {
      try {
        this.loading = true
        let userId
        if (this.$route.meta.isProfile) {
          userId = this.$store.getters['auth/user'].user._id
        } else {
          userId = this.$route.params.userId
        }
        const user = await this.$store.dispatch('users/get', userId)
        const bankDetailsList = await this.getBankAccounts(user)
        this.user = user.clone()
        this.bankDetailsList = [...bankDetailsList]
        if (!this.user.features) {
          this.$set(this.user, 'features', {})
        }
        if (!this.user.products) {
          this.$set(this.user, 'products', [])
        }
        if (!this.user.pushNotificationsDevices) {
          this.$set(this.user, 'pushNotificationsDevices', [])
        }
        this.orders = (await this.$store.dispatch('hs-order/find', {
          query: { userId: userId }
        })).data
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    async getBankAccounts (user) {
      const bankDetailsList = []
      if (user.bankId && user.bankId.length > 0) {
        for (const id of user.bankId) {
          bankDetailsList.push(await this.$store.dispatch('bank-account/get', id))
        }
      } else {
        bankDetailsList.push(new this.$FeathersVuex.api.BankAccount())
        bankDetailsList[0].VWZ = 'Konto 1'
      }
      return bankDetailsList
    }
  }
}
</script>
