<template>
  <CaCard
    class="mb-3"
  >
    <template #header>
      <h4 class="mb-0">
        Email Logs
      </h4>
    </template>
    <template #body>
      <template v-if="loading">
        Lädt...
      </template>
      <template v-else>
        <div
          v-for="log of emailLogs"
          :key="log._id"
          class="card-body"
        >
          <div
            class="form-row align-items-center"
          >
            <div class="col-auto">
              <div
                class="order-icon-wrapper bg-success-light"
              >
                <i
                  class="far fa-lg fa-envelope text-success"
                />
              </div>
            </div>
            <div class="col">
              E-Mail mit dem Betreff <span class="font-weight-bold">{{ log.subject }}</span> versendet an
              <span
                class="font-weight-bold"
              >{{ log.to }} <span /></span>
            </div>
            <div class="col-auto">
              <p class="mb-0 d-inline">
                {{ formatDate(log.createdAt, 'DD.MM.YYYY HH:mm:ss') }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="!loading && emailLogs.length < total"
          class="text-center"
        >
          <button
            class="btn btn-primary-light"
            @click="loadEmailLogs"
          >
            Weitere laden
          </button>
        </div>
      </template>
    </template>
  </CaCard>
</template>

<script>
import CaCard from '@/components/Card.vue'
import feathers from '@/api'
import formatDate from '@/filters/formatDate.js'
export default {
  components: {
    CaCard
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    emailLogs: [],
    total: null,
    skip: 0
  }),
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    formatDate,
    async fetchData () {
      try {
        this.loading = true
        await this.loadEmailLogs()
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async loadEmailLogs () {
      const emailLogReq = await feathers.service('mail-log').find({
        query: {
          userId: this.user._id,
          $sort: { createdAt: -1 },
          $limit: 5,
          $skip: this.skip
        }
      })
      this.skip += emailLogReq.limit
      this.total = emailLogReq.total
      this.emailLogs.push(...emailLogReq.data)
    }
  }
}
</script>

<style>

</style>
